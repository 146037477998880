<template>
	<van-pull-refresh v-model="isRefresh" @refresh="onRefresh">
		<div class="prescription-list" style="min-height: 100vh;" ref="listView">
			<div class="prescription-item" v-for="(item, index) in dataList" :key="index">
				<div class="row">
					<span class="title" v-text="item.diagnosis"></span>
					<span class="time" v-text="item.registerDate"></span>
				</div>
				<div :class="{'notice': true, 'hide': !item.doctoradvice}">醫囑：{{item.doctoradvice}}</div>
				<div class="row">
					<img src="../../../../assets/imgs/mobile/user_center/img_nanys@2x.png" alt="" class="icon-doctor">
					<span class="doctor-name" v-text="item.doctorName"></span>
					<span class="clinic-name">（{{item.clinicName}}）</span>
				</div>
				<div class="operate">
					<router-link :to="'/user/booking/record/detail/' + item.clientRegisterId" class="btn-detail">處方詳情</router-link>
					<div class="row">
						<a :href="`tel:${item.clinicTel}`" class="col tel">
							<img src="../../../../assets/imgs/mobile/user_center/img_iphone@2x.png" alt="" class="icon-tel">
							<span class="tel-hint">呼叫診所</span>
						</a>
						<!-- <div class="col gps">
							<img src="../../../../assets/imgs/mobile/user_center/img_dib@2x.png" alt="" class="icon-gps">
							<span class="gps-hint">地圖導航</span>
						</div> -->
					</div>
				</div>
			</div>
			<div class="load-more" v-if="isLoadMore">
				<div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
				<span class="text">正在加载...</span>
			</div>
			<div class="load-finished" v-if="isLoadFinished">已全部加載</div>
		</div>
		<loading v-if="loading"/>
	</van-pull-refresh>
</template>

<script>
	import Loading from '@/layouts/Loading.vue'
	import record from '@/views/mixins/record'
	export default {
		mixins: [
			record
		],
		components: {
			Loading
		},
		data(){
			return {
				loading: true,
				isRefresh: false,
				isLoadMore: false,
				isLoadFinished: false,
				pageNo: 1,
				pageSize: 10,
				maxPage: 1,
				clientId: this.$store.getters.clientId,
				dataList: [],
				loadMore: false,
			}
		},
		mounted: function(){
			this.onRefresh()
		},
		methods: {
			onRefresh() {
				this.pageNo = 1
				this.loading = true
				this.isLoadMore = false
				this.isLoadFinished = false
				this.loadData(this.clientId, this.pageNo, this.pageSize, (data, error)=>{
					this.loading = false
					this.isRefresh = false
					this.dataList = (data && data.list) || []
					var total = (data && data.total) || 0
					if(total > 0) {
						this.maxPage = Math.floor((total + this.pageSize - 1)/this.pageSize)
						if(this.pageNo >= this.maxPage) {
							this.isLoadFinished = true
						}
					} else {
						if(!error) {
							this.isLoadFinished = true
						}
					}
				})
			},
			handleScroll (event) {
				if((window.innerHeight + window.pageYOffset) > this.$refs.listView.clientHeight) {
					// 加载更多
					if(!this.loading && !this.isLoadMore && this.pageNo < this.maxPage) {
						this.isLoadMore = true
						this.loading = true
						this.loadData(this.clientId, this.pageNo+1, this.pageSize, (data, error)=>{
							this.loading = false
							this.isLoadMore = false
							this.dataList = this.dataList.concat((data && data.list) || [])
							if(!error) {
								this.pageNo = this.pageNo + 1
								var total = (data && data.total) || 0
								if(total > 0) {
									var maxPage = Math.floor((total + this.pageSize - 1)/this.pageSize)
									if(this.pageNo >= maxPage) {
										this.isLoadFinished = true
									}
								}
							}
						})
					}
				}
			}
		},
		created () {
			window.addEventListener('scroll', this.handleScroll);
		},
		destroyed () {
			window.removeEventListener('scroll', this.handleScroll);
		},
	}
</script>

<style lang="scss" scoped>
	.prescription-list {
		display: flex;
		flex-direction: column;
	}

	.prescription-list > .prescription-item {
		margin: 16px 24px;
		background-color: #fff;
		padding: 0px 17px;
		display: flex;
		flex-direction: column;
		position: relative;
	}

	.prescription-list > .prescription-item::after {
		content: '';
		position: absolute;
		left: 24px;
		right: 24px;
		bottom: 111px;
		height: 1px;
		box-sizing: border-box;
		border-bottom: 1px dashed #DDDDDD;
	}

	.prescription-list > .prescription-item .row {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
	}

	.prescription-list > .prescription-item .row:nth-child(1) {
		justify-content: space-between;
	}

	.prescription-list > .prescription-item .title {
		height: 33px;
		line-height: 33px;
		margin-top: 39px;
		font-size: 34px;
		color: #231F20;
		font-weight: bold;
	}

	.prescription-list > .prescription-item .time {
		height: 33px;
		line-height: 33px;
		margin-top: 39px;
		font-weight: 400;
		color: #969696;
		font-size: 24px;
	}

	.prescription-list > .prescription-item .notice {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		height: 27px;
		line-height: 27px;
		margin-top: 20px;
	}

	.prescription-list > .prescription-item .notice.hide {
		display: none;
	}

	.prescription-list > .prescription-item .icon-doctor {
		width: 26px;
		height: 27px;
		margin-right: 9px;
		margin-top: 46px;
		margin-bottom: 26px;
	}

	.prescription-list > .prescription-item .doctor-name,
	.prescription-list > .prescription-item .clinic-name {
		color: #231F20;
		font-size: 28px;
		font-weight: 400;
		margin-top: 46px;
		margin-bottom: 26px;
	}

	.prescription-list > .prescription-item .operate {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		height: 110px;
	}

	.prescription-list > .prescription-item .col {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-right: 13px;
		background-color: #fff;
	}

	.prescription-list > .prescription-item .col:active {
		opacity: 7;
	}

	.prescription-list > .prescription-item .col:nth-child(1) {
		position: relative;
		// margin-right: 96px;
	}

	.prescription-list > .prescription-item .col:nth-child(1)::after {
		content: '';
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 1px;
		height: 30px;
		right: -48px;
		background-color: #fff;
	}

	.prescription-list > .prescription-item .icon-tel {
		width: 20px;
		height: 20px;
		margin-bottom: 12px;
	}

	.prescription-list > .prescription-item .icon-gps {
		width: 16px;
		height: 20px;
		margin-bottom: 12px;
	}

	.prescription-list > .prescription-item .tel-hint,
	.prescription-list > .prescription-item .gps-hint {
		color: #969696;
		font-weight: 400;
		font-size: 24px;
		line-height: 23px;
	}

	.prescription-list > .prescription-item .btn-detail {
		width: 148px;
		height: 48px;
		background-color: #fff;
		border: 1px solid #85D2D8;
		border-radius: 24px;
		line-height: 48px;
		text-align: center;
		color: #36C4D0;
		font-size: 26px;
		font-weight: 400;
		margin: auto 0px auto 37px;
	}

	.prescription-list > .prescription-item .btn-detail:active {
		opacity: 7;
	}

	// loadmore
	.load-more {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.load-more > .text {
		margin-left: 24px;
	}
	.lds-default {
		display: inline-block;
		position: relative;
		width: 80px;
		height: 80px;
	}
	.lds-default div {
		position: absolute;
		width: 6px;
		height: 6px;
		background: #36C4D0;
		border-radius: 50%;
		animation: lds-default 1.2s linear infinite;
	}
	.lds-default div:nth-child(1) {
		animation-delay: 0s;
		top: 37px;
		left: 66px;
	}
	.lds-default div:nth-child(2) {
		animation-delay: -0.1s;
		top: 22px;
		left: 62px;
	}
	.lds-default div:nth-child(3) {
		animation-delay: -0.2s;
		top: 11px;
		left: 52px;
	}
	.lds-default div:nth-child(4) {
		animation-delay: -0.3s;
		top: 7px;
		left: 37px;
	}
	.lds-default div:nth-child(5) {
		animation-delay: -0.4s;
		top: 11px;
		left: 22px;
	}
	.lds-default div:nth-child(6) {
		animation-delay: -0.5s;
		top: 22px;
		left: 11px;
	}
	.lds-default div:nth-child(7) {
		animation-delay: -0.6s;
		top: 37px;
		left: 7px;
	}
	.lds-default div:nth-child(8) {
		animation-delay: -0.7s;
		top: 52px;
		left: 11px;
	}
	.lds-default div:nth-child(9) {
		animation-delay: -0.8s;
		top: 62px;
		left: 22px;
	}
	.lds-default div:nth-child(10) {
		animation-delay: -0.9s;
		top: 66px;
		left: 37px;
	}
	.lds-default div:nth-child(11) {
		animation-delay: -1s;
		top: 62px;
		left: 52px;
	}
	.lds-default div:nth-child(12) {
		animation-delay: -1.1s;
		top: 52px;
		left: 62px;
	}
	@keyframes lds-default {
		0%, 20%, 80%, 100% {
			transform: scale(1);
		}
		50% {
			transform: scale(1.5);
		}
	}
	//loadmore end

	.load-finished {
		height: 44px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		position: relative;
		font-size: 24px;
		font-weight: 400;
		color: #99999991;
	}

	.load-finished::before {
		content: '';
		width: 120px;
		height: 1px;
		background-color: #99999991;
		right: -130px;
		margin-right: 20px;
		top: 50%;
		transform: translateY(-50%);
	}

	.load-finished::after {
		content: '';
		width: 120px;
		height: 1px;
		background-color: #99999991;
		left: -130px;
		top: 50%;
		margin-left: 20px;
		transform: translateY(-50%);
	}
</style>
